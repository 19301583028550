import { SvgIconName } from "../../../packages/blueprint-ui/src/components/SvgIcon/types";

export const bloomreachIconMappings: Record<string, SvgIconName> = {
  "green-checkmark": "check-circle",
  "decorative-credit": "credit",
  "decorative-fairprice": "fair-price",
  "decorative-delivery": "delivery",
  "decorative-18mm-thickness": "18mm-thickness",
  "decorative-multiple-doors": "multiple-doors",
  "decorative-advice": "advice",
  "decorative-onlineaccount": "online-account",
  "decorative-news": "news",
  "Decorative-Charge-Full": "charge-full",
  "Decorative-Charge-Medium": "charge-medium",
  "Decorative-Charge-Low": "charge-low",
  "decorative-design": "design",
  "decorative-clickncollect": "click-collect",
  "decorative-app-love": "app-love",
  "decorative-expert": "expert",
  "decorative-lock": "lock",
  "decorative-tap": "tap",
  "decorative-time": "time",
  "Decorative-High-Strength": "high-strength",
  "Decorative-Maintenance-Free": "maintenance-free",
  "Decorative-Perfect": "perfect",
  "drawing-take-off": "drawing-take-off",
  "branch-3d-design": "3D-design",
  "branch-bathroom-showroom": "bathroom",
  "brick-matching": "brick-matching",
  "brick-library": "brick-library",
  "free-hot-drinks": "free-drinks",
  "free-wifi": "free-wifi",
  "branch-kitchen": "kitchen",
  "landscaping-display": "landscaping",
  toilets: "toilets",
  basket: "basket",
  "decorative-electrical-lighting-showroom": "electric-light-showroom",
  "decorative-roofing-take-off": "roofing-take-off",
  "decorative-tool-plant-hire": "tool-plant-hire",
  "joinery-display": "joinery-display",
  "paint-mixing": "paint-mixing",
  "timber-cutting": "timber-cutting",
  "underfloor-heating": "underfloor-heating",
  "underfloor-heating-system": "underfloor-heating",
  "Decorative-Long-Lasting": "long-lasting",
  "decorative-Save-water": "save-water",
  "decorative-eco-home": "eco-home",
  "decorative-carbon-footprint": "carbon-footprint",
  "decorative-Save-the-planet": "save-the-planet",
  "Decorative-Temperature": "temperature",
  "Decorative-Battery": "battery",
  "Decorative-All-Weather": "all-weather",
  "Decorative-Electric-Charging": "electric-charging",
  "Decorative-Versatile": "versatile",
  "Decorative-Durable": "durable",
  "decorative-showroom": "showroom",
  "decorative-discount": "discount",
  "Decorative-Soundproof": "soundproof",
  "Decorative-Recycle": "recycle",
  "Decorative-Noncombustible": "noncombustible",
};

const useBloomreachIconMapping = (iconName: string) => bloomreachIconMappings[iconName];

export default useBloomreachIconMapping;
